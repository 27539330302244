import { useMutation } from 'react-query';

import { createproductNotFoundRequests } from 'app/api/products/service';
import { ProductNotFoundRequest } from 'app/api/products/types';
import { notificationObserver } from 'utils/observer';
import { globalMessages } from 'utils/messages';

interface UseFeedbackFormMutationProps {
  onSuccessAction?: () => void;
}

export const useFeedbackFormMutation = ({ onSuccessAction }: UseFeedbackFormMutationProps) => {
  const { mutate: handleCreateProductNotFoundRequest, isLoading: isCreateFeedbackFormLoading } = useMutation(
    (data: ProductNotFoundRequest) => createproductNotFoundRequests(data),
    {
      onSuccess: () => {
        notificationObserver.publish({
          type: 'success',
          title: globalMessages.thankYouForm,
        });

        onSuccessAction?.();
      },
      onError: () => {
        notificationObserver.publish({
          type: 'error',
          title: 'Something went wrong',
        });
      },
    },
  );
  return {
    isCreateFeedbackFormLoading,
    handleCreateProductNotFoundRequest,
  };
};
