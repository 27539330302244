import { ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import { customTheme } from 'theme/MuiThemeProvider/theme';

import { styles } from './styles';

const messages = {
  main: 'A simpler way to source ',
  seedGrain: 'seed & grain',
};

export const Pill = ({ text, color }: { text: string; color: string }): ReactElement => {
  return (
    <Box component="span" sx={styles.pill(color)}>
      {text}
    </Box>
  );
};
export const HomePageBanner = (): ReactElement => {
  return (
    <Box sx={styles.container}>
      <Typography component="h1" sx={styles.title}>
        {messages.main}
        <Typography component="span" sx={styles.seedGrain}>
          {messages.seedGrain}
        </Typography>
      </Typography>
      <Typography sx={styles.description}>
        Effortlessly <Pill text="source" color={customTheme.palette.primary.main} />{' '}
        <Pill text="quote" color={customTheme.palette.secondary.main} />
        {' and '} <Pill text="procure" color="#FF9737" /> from leading companies across the globe.
      </Typography>
    </Box>
  );
};
